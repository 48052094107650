import BaseController from "./BaseController";
import http from "../../Store/baseHttp";
import ResponseObject from "../../DataObjects/ResponseObject";
import { defer } from "react-router-dom";

class TransactionHistoryController extends BaseController {
  async index({ request, params }) {
    const url = new URL(request.url);
    const search = new URLSearchParams(url.search);
    search.append("sort_type", "DESC");

    try {
      let response = await http.get("admin/request/list?" + search.toString());
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  static async scopeIndex({ request, params }) {
    const url = new URL(request.url);
    const search = new URLSearchParams(url.search);
    search.append("sort_type", "DESC");
    search.append("organization_token", params.id);

    try {
      let response = await http.get("admin/request/list?" + search.toString());
      response = ResponseObject.from(response);
      return defer({ data: response });
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  async post({ request }) {
    try {
      let response = await http.post(
        "admin/request/kyc/comment",
        Object.fromEntries(await request.formData())
      );
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  async put({ request }) {
    try {
      let response = await http.put(
        "/admin/request/kyc/status",
        Object.fromEntries(await request.formData())
      );
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  async destroy({ request, params }) {
    try {
      let response = await http.delete("/request/" + params.id);
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }

  static async dashboard(v) {
    const { request } = v;
    const url = new URL(request.url);
    const search = new URLSearchParams(url.search);
    search.append("sort_type", "DESC");
    search.append("page_size", "5");

    try {
      let response = await http.get("/request/list?" + search.toString());
      response = ResponseObject.from(response);
      return defer({ data: response });
    } catch (e) {
      return defer({ data: ResponseObject.from(e?.response) });
    }
  }

  static async view(v) {
    const { request, params } = v;
    try {
      let response = await http.get("admin/request/single/" + params.id);
      response = ResponseObject.from(response);
      return defer({ data: response });
    } catch (e) {
      return defer({ data: ResponseObject.from(e?.response) });
    }
  }
}

export default TransactionHistoryController;
