import Panel from "../../../Components/Dashboard/Panel";
import useTitle from "../../../Hooks/useTitle";
import { Await, useLoaderData, useParams } from "react-router-dom";
import { Suspense } from "react";
import { Link } from "react-router-dom";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const CompanyView = () => {
  useTitle("Company View");
  const data = useLoaderData();
  const { id } = useParams();

  return (
    <div>
      <div className="my-5 flex items-center justify-between">
        <Link to="/dashboard/company" className="rounded-[8px] bg-white p-1">
          <BsChevronLeft className="h-6 w-6" />
        </Link>
        <Link to="approval" className="rounded-[8px] bg-white p-1">
          <BsChevronRight className="h-6 w-6" />
        </Link>
      </div>
      <Suspense fallback={<p>Loading...</p>}>
        <Await resolve={data}>
          {(data) => {
            const item = data?.data;
            return (
              <div className="mt-5">
                <div className="flex items-center justify-between"></div>
                <Panel>
                  <div className="flex w-full flex-col items-start justify-start divide-y divide-gray-500/50">
                    <div className="grid w-full grid-cols-1 gap-5 p-[40px] md:grid-cols-5">
                      <div className="col-span-2">
                        <h1 className="text-[24px] font-[900] leading-[27px]">
                          Company Details
                        </h1>
                        <p className="text-[14px] font-[400] leading-[21px]">
                          Basic information about the Company
                        </p>
                        <div className="mt-8 text-[13px]">
                          {item?.approval_status ? (
                            <span className="rounded-full bg-[#EBECFE] px-3 py-1 font-[600] text-[#610BEF]">
                              Approved
                            </span>
                          ) : (
                            <span className="rounded-full bg-[#FFFBEB] px-3 py-1 font-[600] text-[#F59E0B]">
                              Pending
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-span-3 grid grid-cols-2 gap-y-[24px] gap-x-[78px]">
                        <div className="col-span-1">
                          <p className="text-[14px] font-[400] leading-[21px]">
                            Company Name
                          </p>
                          <p className="text-[18px] font-[700] leading-[21px]">
                            {item?.company_name}
                          </p>
                        </div>
                        <div className="col-span-1">
                          <p className="text-[14px] font-[400] leading-[21px]">
                            Category Of Business
                          </p>
                          <p className="text-[18px] font-[700] leading-[21px]">
                            {item?.industry}
                          </p>
                        </div>
                        <div className="col-span-1">
                          <p className="text-[14px] font-[400] leading-[21px]">
                            Registration Number
                          </p>
                          <p className="text-[18px] font-[700] leading-[21px]">
                            {item?.registration_number}
                          </p>
                        </div>
                        <div className="col-span-1">
                          <p className="text-[14px] font-[400] leading-[21px]">
                            Fedhagap Approved Company
                          </p>
                          <p className="text-[18px] font-[700] leading-[21px]">
                            {item?.approval_status ? "Yes" : "No"}
                          </p>
                        </div>
                        <div className="col-span-1">
                          <p className="text-[14px] font-[400] leading-[21px]">
                            Country of operation
                          </p>
                          <p className="text-[18px] font-[700] leading-[21px]">
                            {item?.country}
                          </p>
                        </div>
                        <div className="col-span-1">
                          <p className="text-[14px] font-[400] leading-[21px]">
                            State
                          </p>
                          <p className="text-[18px] font-[700] leading-[21px]">
                            {item?.state}
                          </p>
                        </div>
                        <div className="col-span-1">
                          <p className="text-[14px] font-[400] leading-[21px]">
                            City
                          </p>
                          <p className="text-[18px] font-[700] leading-[21px]">
                            {item?.city}
                          </p>
                        </div>
                        <div className="col-span-1">
                          <p className="text-[14px] font-[400] leading-[21px]">
                            Email Address
                          </p>
                          <p className="text-[18px] font-[700] leading-[21px]">
                            {item?.email}
                          </p>
                        </div>
                        <div className="col-span-1">
                          <p className="text-[14px] font-[400] leading-[21px]">
                            Contact Person Name
                          </p>
                          <p className="text-[18px] font-[700] leading-[21px]">
                            {item?.contact_person_name}
                          </p>
                        </div>
                        <div className="col-span-1">
                          <p className="text-[14px] font-[400] leading-[21px]">
                            Contact Person Phone Number
                          </p>
                          <p className="text-[18px] font-[700] leading-[21px]">
                            {item?.contact_person_phone_number}
                          </p>
                        </div>
                        <div className="col-span-1">
                          <p className="text-[14px] font-[400] leading-[21px]">
                            Website
                          </p>
                          <p className="text-[18px] font-[700] leading-[21px]">
                            {item?.website}
                          </p>
                        </div>
                        <div className="col-span-full">
                          <p className="text-[14px] font-[400] leading-[21px]">
                            Address
                          </p>
                          <p className="text-[18px] font-[700] leading-[21px]">
                            {item?.contact_address}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Panel>
              </div>
            );
          }}
        </Await>
      </Suspense>
    </div>
  );
};

export default CompanyView;
