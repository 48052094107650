import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  ScrollRestoration,
} from "react-router-dom";
import AuthLayout from "../Layouts/AuthLayout";
import Login from "../Pages/Auth/Login";
import ForgetPassword from "../Pages/Auth/ForgetPassword";
import Verification from "../Pages/Auth/Verification";
import ResetPassword from "../Pages/Auth/ResetPassword";
import PasswordReset from "../Pages/Auth/PasswordReset";
import Dashboard from "../Layouts/Dashboard";
import {
  ForgotPasswordService,
  LoginService,
  RegisterService,
  ResendPasswordVerificationService,
  ResendVerificationService,
  ResetPasswordService,
  VerifyPasswordService,
  VerifyResetService,
  VerifyService,
} from "../Services/AuthServices";
import Register from "../Pages/Auth/Register";
import PasswordResetVerify from "../Pages/Auth/PasswordResetVerify";
import DashboardIndex from "../Pages/App/Dashboard/DashboardIndex";
import ResendVerification from "../Pages/Auth/ResendVerification";
import Transaction from "../Pages/App/Transaction/Transaction";
import DashboardController from "../Services/Dashboard/DashboardController";
import TransactionHistoryController from "../Services/Dashboard/TransactionHIstoryController";
import DashboardPanel from "../Components/Dashboard/Dashboard";
import TransactionView from "../Pages/App/Transaction/TransactionView";
import InvoiceCreate from "../Pages/App/Invoice/InvoiceCreate";
import Invoice from "../Pages/App/Invoice/Invoice";
import InvoiceService from "../Services/Dashboard/InvoiceService";
import Members from "../Pages/App/Setting/Members";
import SupportService from "../Services/Dashboard/SupportService";
import SupportIndex from "../Pages/App/Support/SupportIndex";
import SupportWrapper from "../Pages/App/Support/SupportWrapper";
import FAQService from "../Services/Dashboard/FAQService";
import MemberService from "../Services/Dashboard/MemberService";
import Complete from "../Pages/Auth/Complete";
import OnboardService from "../Services/Dashboard/OnboardService";
import KYCController from "../Services/Dashboard/KYCController";
import Customers from "../Pages/App/Customer/Customers";
import Users from "../Pages/App/Customer/Users";
import Roles from "../Pages/App/Setting/Roles";
import RolesService from "../Services/Dashboard/RolesService";
import TransactionApproval from "../Pages/App/Transaction/TransactionApproval";
import CustomerView from "../Pages/App/Customer/CustomerView";
import CustomerService from "../Services/Dashboard/CustomerService";
import CustomerApproval from "../Pages/App/Customer/CustomerApproval";
import Error from "../Pages/Errors/Error";
import AuditLogs from "../Pages/App/Customer/AuditLogs";
import AuditLogService from "../Services/Dashboard/AuditLogService";
import Company from "../Pages/App/Company/Company";
import CompanyIndex from "../Pages/App/Company/CompanyIndex";
import CompanyService from "../Services/Dashboard/CompanyService";
import CompanyView from "../Pages/App/Company/CompanyView";
import CompanyTransaction from "../Pages/App/Company/CompanyTransaction";
import FAQIndex from "../Pages/App/Support/FAQIndex";
import SupportView from "../Pages/App/Support/SupportView";
import Profile from "../Pages/App/Setting/Profile";
import ProfileService from "../Services/Dashboard/ProfileService";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <>
          <ScrollRestoration />
          <Outlet />
        </>
      }
         errorElement={<Error />}
    >
      <Route path="/" element={<Navigate to="/admin/login" replace />} />
      <Route path="/admin" element={<AuthLayout />}>
        <Route exact path="login" element={<Login />} action={LoginService} />
        <Route
          exact
          path="register"
          element={<Register />}
          action={RegisterService}
        />
        <Route
          exact
          path="forgot-password"
          element={<ForgetPassword />}
          action={ForgotPasswordService}
        />
        <Route
          exact
          path="verify"
          element={<Verification />}
          action={VerifyService}
        >
          <Route
            exact
            path="resend"
            element={<ResendVerification />}
            action={ResendVerificationService}
          />
        </Route>
        <Route
          exact
          path="complete/onboard/:id"
          action={OnboardService.apply().action}
          element={<Complete />}
        />
        <Route
          exact
          path="password-reset-verify"
          element={<PasswordResetVerify />}
          action={VerifyPasswordService}
        />
        <Route
          exact
          path="resend-password-verify"
          action={ResendPasswordVerificationService}
        />
        <Route
          exact
          path="reset-password"
          element={<ResetPassword />}
          action={ResetPasswordService}
        />
        <Route
          exact
          path="password-reset"
          element={<PasswordReset />}
          action={VerifyResetService}
        />
      </Route>

      <Route
        path="/dashboard"
        element={<Dashboard />}
        action={KYCController.apply().action}
      >
        <Route
          element={<DashboardIndex />}
          loader={DashboardController.apply().loader}
        >
          <Route
            index
            element={<DashboardPanel />}
            loader={DashboardController.apply().loader}
          />
        </Route>
        <Route
            element={<Profile />}
            path="profile"
            action={ProfileService.apply().action}
        />
        <Route path="transactions">
          <Route
            exact
            index
            element={<Transaction />}
            loader={TransactionHistoryController.apply().loader}
          />
          <Route
            exact
            path=":id"
            element={<TransactionView />}
            action={TransactionHistoryController.apply().action}
            loader={TransactionHistoryController.view}
          />
          <Route
            exact
            path=":id/approval"
            element={<TransactionApproval />}
            action={TransactionHistoryController.apply().action}
            loader={TransactionHistoryController.view}
          />
        </Route>
        <Route
          exact
          path="invoices"
          element={<Invoice />}
          action={InvoiceService.email}
          loader={InvoiceService.apply().loader}
        />
        <Route
          exact
          path="invoice/create"
          element={<InvoiceCreate />}
          action={InvoiceService.apply().action}
          loader={InvoiceService.template}
        />
        <Route exact path="settings">
          <Route
            path="user-management"
            element={<Members />}
            loader={MemberService.apply().loader}
            action={MemberService.apply().action}
          />
          <Route
            path="roles"
            element={<Roles />}
            loader={RolesService.apply().loader}
            action={RolesService.apply().action}
          />
        </Route>
        <Route path="customers" element={<Customers />}>
          <Route
            exact
            index
            element={<Users />}
            loader={CustomerService.apply().loader}
            action={CustomerService.apply().action}
          />
          <Route
            exact
            path="compliant"
            element={<Users />}
            loader={CustomerService.apply().loader}
            action={CustomerService.apply().action}
          />
          <Route
            exact
            path="non-compliant"
            element={<Users />}
            loader={CustomerService.apply().loader}
            action={CustomerService.apply().action}
          />
          <Route
            exact
            path="pending-approval"
            element={<Users />}
            loader={CustomerService.apply().loader}
            action={CustomerService.apply().action}
          />
        </Route>
        <Route path="customers/:id">
          <Route
            index
            element={<CustomerView />}
            loader={CustomerService.view}
          />
          <Route
            path="approval"
            element={<CustomerApproval />}
            loader={CustomerService.view}
            action={CustomerService.apply().action}
          />
          <Route
            path="audit-logs"
            element={<AuditLogs />}
            loader={AuditLogService.apply().loader}
          />
        </Route>
        <Route path="company" element={<Company />} action={CompanyService.apply().action}>
          <Route
            exact
            index
            element={<CompanyIndex />}
            loader={CompanyService.apply().loader}
            action={CompanyService.apply().action}
          />
          <Route
            exact
            path="suggested"
            element={<CompanyIndex />}
            loader={CompanyService.apply().loader}
            action={CompanyService.apply().action}
          />
        </Route>
        <Route path="company/:id">
          <Route
            exact
            index
            element={<CompanyView />}
            loader={CompanyService.view}
          />
          <Route
            exact
            path="approval"
            element={<CompanyTransaction />}
            action={InvoiceService.email}
            loader={TransactionHistoryController.scopeIndex}
          />
        </Route>
        <Route element={<SupportWrapper />}>
          <Route
            exact
            path="faq"
            element={<FAQIndex />}
            loader={FAQService.apply().loader}
            action={FAQService.apply().action}
          />
          <Route
            exact
            path="help-and-support"
            element={<SupportIndex />}
            loader={SupportService.apply().loader}
            action={SupportService.apply().action}
          />
        </Route>
        <Route
          exact
          path="help-and-support/:id"
          element={<SupportView />}
          loader={SupportService.view}
          action={SupportService.apply().action}
        />
        <Route path="errors/:id" element={<Error />} />
        <Route
          path="*"
          element={<Navigate to="errors/_404" replace="true" />}
        />
      </Route>
      <Route
        path="*"
        element={
          <div className="h-screen w-full">
            <Error />
          </div>
        }
      />
    </Route>
  )
);

const Index = () => {
  return <RouterProvider router={router} />;
};

export default Index;
